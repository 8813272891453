// This discussion on slot https://stackoverflow.com/a/65307038
// https://morioh.com/p/8ed802e66c8e
<template>
  <transition name="modal-fade">
    <form class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
      <div class="modal--wrapper">
        <div class="modal--header" id="modalTitle">
          <h2 class="modal--title">
            <slot name="header" class="">Title</slot>
          </h2>
          <button class="modal--close" @click.prevent="onResponse(false)" aria-label="Close modal">&#10005;</button>
        </div>
        <hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
        <div class="modal--body" id="modalDescription">
          <slot name="body" class=""></slot>
        </div>
        <div class="modal--footer">
            <slot name="footer">
              <button type="button" class="modal--button" style="width: 49%;" @click="onResponse(false)" aria-label="Decline">
                <slot name="false" :onResponse="onResponse">False</slot>
              </button>
              <button type="button" class="modal--button" style="width: 49%;" @click="onResponse(true)" aria-label="Accept">
                <slot name="true" :onResponse="onResponse">True</slot>
              </button>
            </slot>
        </div>
      </div>
    </form>
  </transition>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Modal",
  methods: {
    ...mapActions(["setOverlaying"]),
    onResponse(response) {
      this.setOverlaying(false);
      this.$emit("onResponse", response);
    },
  },
};
</script>

<style scoped>
/* modal */
.modal {
  background-color: transparent;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 50%;
  margin-left: -50px;
  top: 50%;
  margin-top: -50px;
  z-index: 10000;
}
.modal {
  background-color: rgba(243, 244, 246, 1);
  position: absolute;
  height: auto;
  width: 95%;
  left: 0%;
  right: 0%;
  top: 5%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -5px;
  z-index: 10000;
}
.modal--wrapper {
  width: 100%;
  margin: 0px auto;
  padding: 0px;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}
.modal--header {
  display: flex;
  margin: 0;
}
.modal--title {
  flex: auto;
  text-align: center;
  margin: 1rem 0;
  margin: 10px 0 5px 0;
}
.modal--close {
  background-color: rgba(209, 213, 219, 1);
  font-weight: 900;
  font-size: larger;
  padding: 0.75rem 1rem;
  border-radius: 0;
  cursor: pointer;
}
.modal--body {
  padding: 5px;
  padding-top: 2rem;
  margin: 0;
}
.modal--footer {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.modal--button {
  position: relative;
  /* background-color: transparent; */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-box-align: stretch;
  height: 45px;
  width: 100%;
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 5px;
  border-radius: 4px;
  text-decoration: none;
  cursor: not-allowed;
  z-index: 0;
  cursor: pointer;
  background-color: rgba(209, 213, 219, 1);
  /* appearance: none; */
  transition: all 150ms ease-in-out 0s;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}
.modal--button:hover,
.modal--close:hover {
  opacity: 0.5;
}
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
/* mini */
@media only screen and (min-width: 481px) {
  .modal {
    background-color: rgba(243, 244, 246, 1);
    position: absolute;
    height: auto;
    width: 75%;
    left: 0%;
    right: 0%;
    top: 15%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -15px;
    z-index: 10000;
  }
  .modal--button {
    margin-top: 48px;
  }
}
/* max */
@media only screen and (min-width: 981px) {
  .modal {
    background-color: rgba(243, 244, 246, 1);
    position: absolute;
    height: auto;
    width: 50%;
    left: 0%;
    right: 0%;
    top: 15%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -15px;
    z-index: 10000;
  }
}
</style>