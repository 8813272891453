<template>
  <div>
    <Header></Header>
    <!-- {{this.$route.name}} -->
    <div class="confirm">
      <form class="confirm--form" @submit.prevent="onLogin" v-if="!profile">
				<div class="confirm--header">
					<h2 class="confirm--title">Re-authenticate to continue</h2>
				</div>
				<div v-if="!!validations.length" class="validations">
					<ul style="text-align: left;"><li style="list-style-type: disc;" v-for="(validation, index) in validations" :key="index">{{validation}}</li></ul>
				</div>
				<div class="confirm--item">
					<label class="confirm--label" for="password">Password: </label>
					<input class="confirm--input" type="password" name="password" id="password" v-model="password" @blur="onBlur($event)" placeholder="Enter your password" aria-required="true" aria-invalid="false" required />
				</div>
				<div class="confirm--item">
					<button class="confirm--button" :class="{isValid: isValid}" :disabled="!isValid" type="submit">Re-Authenticate</button>
				</div>
			</form>
      <!-- <form @onResponse="onResponse" class="confirm--form" v-if="profile"> -->
      <Modal @onResponse="onResponse" v-show="hasInvite">
        <template v-slot:header>
          Accept Request
        </template>
        <template v-slot:body>
          <p>Kindly accept the request to join and participate in the {{ title }} survey and data gathering process.</p><br>
          <p>P.S: This action is permanent, be sure that you appropriately maaking the right choice.</p>
        </template>
        <template v-slot:false>
          Decline
        </template>
        <template v-slot:true>
          Accept
        </template>
      </Modal>
      <!-- </form> -->
		</div>
		<Main></Main>
		<Footer></Footer> 
  </div>
</template>

<script>
import Header from "@/components/partials/Header.vue";
import Footer from "@/components/partials/Footer.vue";
import Modal from "@/components/partials/Modal.vue";
import Main from "@/components/partials/Main.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Confirm",
  components: {
    Header,
    Footer,
    Modal,
    Main,
  },
  data() {
    return {
      validations: [],
      password: "",
      email: "",
      title: "",
      id: "",
      hasInvite: false,
    };
  },
  watch: {
    hasInvite() {
      if (this.hasInvite) this.setOverlaying(true);
      else this.setOverlaying(false);
    },
  },
  computed: {
    ...mapGetters(["invite", "profile"]),
    isValid() {
      return this.password !== "";
    },
  },
  created() {
    var params = window.location.search.substr(1).split("&");
    if (params.length > 1) {
      var [title, id, email] = params;
      this.title = title.replace(/%20/g, " ").split("=")[1];
      this.id = id.split("=")[1];
      this.email = email.replace("%40", "@").split("=")[1];
      this.setInvite({
        title: this.title,
        id: this.id,
        email: this.email,
      });
    }
  },
  methods: {
    ...mapActions([
      "confirmRequest",
      "setOverlaying",
      "setMessage",
      "setInvite",
      "login",
    ]),
    onBlur(event) {
      event.target.style.borderColor = event.target.value
        ? "rgba(229,231,235, 1)"
        : "rgba(255, 0, 0, 1)";
    },
    async onLogin() {
      await this.login({ email: this.email, password: this.password });
      this.hasInvite = true;
    },
    async onResponse(response) {
      await this.confirmRequest({
        response: response,
        id: this.id,
        email: this.email,
      });
      this.hasInvite = false;
      await this.$router.push({ path: "/dashboard" });
    },
  },
};
</script>

<style scoped>
/* confirm */
.confirm {
  margin: 4rem 0rem;
  padding: 1rem;
  width: 100%;
  height: 100vh;
  background-image: url("../assets/login.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 500ms linear;
}
.confirm--form {
  width: 100%;
  margin: 50px auto;
}
.confirm--title {
  text-align: center;
  margin: 2rem 0;
}
.validations {
  display: flex;
  justify-content: center;
  color: #ff0000;
  padding: 0.5rem;
  border-radius: 5px;
}
.confirm--item {
  display: flex;
  flex-direction: column;
  border: none;
  margin: 0px 0px 20px;
  padding: 0px;
}
.confirm--label {
  font-size: 14px;
  color: rgb(61, 79, 88);
  position: relative;
  height: 16px;
  /*width: 36px;*/
  text-align: left;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.02em;
}
.confirm--input {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(229, 231, 235, 1);
  border-radius: 4px;
  height: 52px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  margin-top: 5px;
  padding-left: 12px;
  padding-right: 12px;
  transition: border-color 150ms ease-in-out 0s;
}
.confirm--button {
  position: relative;
  background-color: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-box-align: stretch;
  height: 45px;
  width: 100%;
  font-size: 18px;
  margin-top: 48px;
  margin-bottom: 5px;
  border-radius: 4px;
  text-decoration: none;
  cursor: not-allowed;
  z-index: 0;
  /* appearance: none; */
  transition: all 150ms ease-in-out 0s;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}
.confirm--button.isValid {
  cursor: pointer;
  background-color: rgba(209, 213, 219, 1);
}
.confirm--button.isValid:hover {
  opacity: 0.5;
}
.confirm--buttons {
  display: flex;
  justify-content: space-between;
}
/* mini */
@media only screen and (min-width: 481px) {
  .confirm--form {
    width: 410px;
    /* margin: 0 auto; */
  }
}
/* max */
@media only screen and (min-width: 981px) {
}
</style>